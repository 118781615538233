import { Marker } from "react-map-gl";
import { MotorbikeMarkerSvg, CarMarkerSvg } from "../../../assets/svg";

interface IProps {
  device: Device;
}

export const DeviceMarker = (props: IProps) => {
  const { device } = props;
  const { latitude, longitude } = device;

  if (latitude === undefined || longitude === undefined) return null;

  return (
    <Marker longitude={longitude} latitude={latitude} anchor="bottom">
      {device.vehicleType == "MOTORCYCLE" ? <MotorbikeMarkerSvg/> : <CarMarkerSvg/>}
    </Marker>
  );
};
