export const baseGraphqlUrl =
  process.env.NODE_ENV === "production"
    ? "https://pegase.api-smt.ovh/graphql"
    : "http://localhost:8000/graphql";
export const baseGraphqlWSUri =
  process.env.NODE_ENV === "production"
    ? "ws://pegase.api-smt.ovh/graphql"
    : "ws://localhost:8000/graphql";
export const REACT_APP_MAPBOX_API_TOKEN =
  process.env.REACT_APP_MAPBOX_API_TOKEN;
