import styled from "styled-components";
import { BodyText } from "../base";

export const BoldBodyText = styled(BodyText)`
  font-weight: 700;
`;

export const SilverBoldBodyText = styled(BoldBodyText)`
  color: #868f96;
`;
