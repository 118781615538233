import styled from "styled-components";
import {
  SilverBoldBodyText,
  AzulBoldCaptionText,
} from "../../../components/text";

type Props = {
  user?: User;
};

export const DataCell = ({ user }: Props) => {
  const device = user?.devices?.[0];

  const userName = user?.username ? user.username : "Position de votre ami";

  const formattedDeviceName = device?.name
    ? `Position de ${device?.name}`
    : "Position de la moto";

  return (
    <DeviceDataWrapper>
      <SilverBoldBodyText>{userName}</SilverBoldBodyText>
      <AzulBoldCaptionText>{formattedDeviceName}</AzulBoldCaptionText>
    </DeviceDataWrapper>
  );
};

const DeviceDataWrapper = styled.div`
  min-width: 191px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  background: ${({ theme }) => theme.background.main};
  box-shadow: 0px 2px 5px rgba(12, 24, 34, 0.08),
    0px 0px 2px 1px rgba(12, 24, 34, 0.05);
  border-radius: 4px;
`;
