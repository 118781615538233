import { DefaultTheme } from "styled-components";

export const darkTheme: DefaultTheme = {
  primary: {
    variant100: "#2B374D",
    variant200: "#536B94",
    variant500: "#8AB2F5",
  },
  secondary: {
    green100: "#1F6636",
    green500: "#40BF6A",
  },
  warning: {
    red200: "#B24452",
    red500: "#FA3D54",
  },
  text: {
    mainText: "#FCFCFC",
    secondaryText: "#ABB2B8",
    inverseText: "#0c1822",
    buttonText: "#ffffff",
  },
  icons: {
    main: "#FCFCFC",
    secondary: "#ABB2B8",
    inverse: "#0c1822",
  },
  background: {
    variant: "#0c1822",
    main: "#11202C",
    surface: "#172836",
  },
  tabBar: {
    background: "#11202C",
  },
  button: {
    black: "#203140",
  },
  separator: {
    main: "#203140",
  },
  shades: {
    black: "#FFFFFF",
    black800: "#FCFCFC",
  },
  shadows: {
    main: "#000000",
  },
  immutableColors: {
    white50: "#FCFCFC",
    neutral100: "#ABB2B8",
    black800: "#0C1822",
  },
  map: {
    styleURL: "mapbox://styles/smtperformances/cl1kvofpb00pi15muy9mzeegj",
  },
};

export const lightTheme: DefaultTheme = {
  primary: {
    variant100: "#B8CFF5",
    variant200: "#7AA5ED",
    variant500: "#0157E7",
  },
  secondary: {
    green100: "#92E49A",
    green500: "#07AB18",
  },
  warning: {
    red200: "#ED8895",
    red500: "#E71D36",
  },
  text: {
    mainText: "#0C1822",
    secondaryText: "#868F96",
    inverseText: "#FCFCFC",
    buttonText: "#FFFFFF",
  },
  icons: {
    main: "#0C1822",
    secondary: "#868F96",
    inverse: "#FCFCFC",
  },
  background: {
    variant: "#F5F5F5",
    main: "#FCFCFC",
    surface: "#FFFFFF",
  },
  tabBar: {
    background: "#FFFFFF",
  },
  button: {
    black: "#0C1822",
  },
  separator: {
    main: "#E6E6E6",
  },
  shades: {
    black: "#000000",
    black800: "#0C1822",
  },
  shadows: {
    main: "#000000",
  },
  immutableColors: {
    white50: "#FCFCFC",
    neutral100: "#ABB2B8",
    black800: "#0C1822",
  },
  map: {
    styleURL: "mapbox://styles/smtperformances/cl1kh6rlx004214o3kwqvim8t",
  },
};
