export const FlashbirdLogoSvg = () => {
  return (
    <svg
      width="129"
      height="44"
      viewBox="0 0 129 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.65795 7.36665V7.39477H13.4148V11.8884H8.65795V22.844H3.78684V11.8884H1.09717V7.39477H3.78684V7.11356C3.78684 2.56372 7.31026 0.848389 10.4625 0.848389C11.7531 0.848389 13.3235 1.12959 14.3285 1.60763L13.472 5.45447L12.8438 5.28575C12.1871 5.11703 11.5818 5.03267 11.0393 5.03267C9.52024 5.03267 8.66366 5.67944 8.66366 7.36665H8.65795Z"
        fill="#0C1822"
      />
      <path
        d="M15.3339 1.21362H20.1993V22.8493H15.3339V1.21362Z"
        fill="#0C1822"
      />
      <path
        d="M38.5014 7.39461L38.4728 22.8495H33.6074V21.1904C32.6024 22.3995 31.1747 23.215 29.1703 23.215C25.1615 23.215 22.0664 19.9025 22.0664 15.122C22.0664 10.3416 25.2186 7.02905 29.1418 7.02905C31.1462 7.02905 32.6367 7.87266 33.636 9.13807V7.39461H38.5014ZM33.636 15.122C33.636 12.9849 32.2026 11.5845 30.3125 11.5845C28.4223 11.5845 26.9889 12.9905 26.9889 15.122C26.9889 17.2535 28.4508 18.6652 30.3125 18.6652C32.1741 18.6652 33.636 17.2873 33.636 15.122Z"
        fill="#0C1822"
      />
      <path
        d="M39.9349 17.9563H44.8631C45.063 18.7999 45.6969 19.6154 47.9583 19.6154C49.5915 19.6154 50.1054 19.1936 50.1054 18.5468C50.1054 17.9 49.62 17.7875 46.9247 17.2251C42.4304 16.2691 40.5974 15.088 40.5974 12.366C40.5974 9.01967 43.3156 7.05688 47.8726 7.05688C52.4296 7.05688 54.4854 8.94094 54.9765 11.8879H50.0483C49.8485 11.1568 49.2146 10.6506 47.6727 10.6506C46.2394 10.6506 45.5256 11.0724 45.5256 11.7473C45.5256 12.2816 45.9824 12.3379 47.8726 12.7315C52.5724 13.7439 55.1193 14.6718 55.1193 17.8719C55.1193 21.4375 51.9385 23.2091 47.9012 23.2091C43.6354 23.2091 40.5117 21.2126 39.9406 17.9563H39.9349Z"
        fill="#0C1822"
      />
      <path
        d="M72.0395 13.1816V22.8493H67.1741V14.2501C67.1741 12.5629 66.6601 11.5787 64.7128 11.5787C62.7655 11.5787 61.8518 12.5067 61.8518 14.6438V22.8493H56.9807V1.21362H61.8518V9.13789C62.9996 7.76 64.4558 7.02888 66.4031 7.02888C69.898 7.02888 72.0452 9.41909 72.0452 13.1816H72.0395Z"
        fill="#0C1822"
      />
      <path
        d="M90.4848 15.1219C90.4848 19.8967 87.3897 23.2149 83.3809 23.2149C81.3764 23.2149 79.9431 22.3994 78.9152 21.1902V22.8493H74.0498V1.21362H78.9152V9.13789C79.9203 7.87248 81.405 7.02888 83.4094 7.02888C87.3326 7.02888 90.4848 10.3189 90.4848 15.1219ZM85.5566 15.1219C85.5566 12.9847 84.1232 11.5843 82.233 11.5843C80.3428 11.5843 78.9095 12.9904 78.9095 15.1219C78.9095 17.2534 80.3428 18.665 82.233 18.665C84.1232 18.665 85.5566 17.2871 85.5566 15.1219Z"
        fill="#0C1822"
      />
      <path
        d="M109.701 7.11341V12.0288C109.387 12.0007 109.187 11.9726 108.724 11.9726C106.434 11.9726 104.23 12.6475 104.23 16.1569V22.8438H99.3647V7.39461H104.23V9.78483C105.207 7.90078 106.806 7.02905 108.81 7.02905C109.181 7.02905 109.467 7.05717 109.701 7.11341Z"
        fill="#0C1822"
      />
      <path
        d="M127.141 1.21362V22.8493H122.27V21.1902C121.242 22.3994 119.809 23.2149 117.804 23.2149C113.795 23.2149 110.7 19.9023 110.7 15.1219C110.7 10.3414 113.852 7.02888 117.776 7.02888C119.78 7.02888 121.27 7.87248 122.27 9.13789V1.21362H127.141ZM122.27 15.1219C122.27 12.9847 120.836 11.5843 118.946 11.5843C117.056 11.5843 115.623 12.9904 115.623 15.1219C115.623 17.2534 117.085 18.665 118.946 18.665C120.808 18.665 122.27 17.2871 122.27 15.1219Z"
        fill="#0C1822"
      />
      <path
        d="M97.7309 2.81616C97.7309 4.41901 96.4974 5.57194 94.7785 5.57194C93.0597 5.57194 91.8262 4.41901 91.8262 2.81616C91.8262 1.21331 93.1168 0.088501 94.7785 0.088501C96.4403 0.088501 97.7309 1.24143 97.7309 2.81616Z"
        fill="#E71D36"
      />
      <path
        d="M92.3464 7.39453H97.2176V22.8494H92.3464V7.39453Z"
        fill="#0C1822"
      />
      <path
        d="M73.1811 34.3152C73.1811 36.9881 71.1207 38.7701 67.891 38.7701H66.109L65.6078 41.3316H62.0439L64.16 30.807H68.8376C71.6219 30.807 73.1811 32.0878 73.1811 34.3152ZM69.6172 34.5936C69.6172 33.8697 69.116 33.5356 68.2808 33.5356H67.1114L66.6102 35.9858H68.058C69.0604 35.9858 69.6172 35.4846 69.6172 34.5936Z"
        fill="#0C1822"
      />
      <path
        d="M78.0254 33.4797L77.8026 34.7047H82.2575L81.7563 37.2663H77.3015L77.023 38.6584H82.2575L81.7007 41.3314H73.0137L75.1297 30.8624H83.6497L83.0928 33.5353H78.0254V33.4797Z"
        fill="#0C1822"
      />
      <path
        d="M91.1118 35.763H94.1745L93.2835 40.2736C92.1698 41.0532 90.6663 41.4987 89.0514 41.4987C85.8216 41.4987 83.7056 39.6054 83.7056 36.8211C83.7056 33.1458 86.4342 30.5286 90.4435 30.5286C92.671 30.5286 94.2302 31.2525 95.1768 32.5889L92.671 34.5379C92.0027 33.647 91.2788 33.3685 90.2765 33.3685C88.4389 33.3685 87.2695 34.705 87.2695 36.5426C87.2695 37.7677 88.1047 38.603 89.5526 38.603C89.8867 38.603 90.2208 38.5473 90.6106 38.4359L91.1118 35.763Z"
        fill="#0C1822"
      />
      <path
        d="M102.249 39.4383H98.2392L97.1811 41.2759H93.4502L100.132 30.807H103.585L106.091 41.2759H102.583L102.249 39.4383ZM101.747 36.9324L101.246 34.2595L99.687 36.9324H101.747Z"
        fill="#0C1822"
      />
      <path
        d="M106.314 40.4963L107.651 37.8791C108.82 38.5473 109.99 38.8258 111.159 38.8258C112.05 38.8258 112.551 38.603 112.551 38.2132C112.551 37.0995 107.54 37.7677 107.54 34.4823C107.54 32.0878 109.544 30.5286 112.83 30.5286C114.389 30.5286 115.781 30.8627 116.728 31.4195L115.447 33.9811C114.612 33.4799 113.609 33.2015 112.551 33.2015C111.493 33.2015 110.992 33.5356 110.992 33.9254C110.992 35.0948 116.004 34.3709 116.004 37.6007C116.004 39.9395 113.943 41.4987 110.714 41.4987C108.932 41.5544 107.317 41.1089 106.314 40.4963Z"
        fill="#0C1822"
      />
      <path
        d="M121.517 33.4797L121.294 34.7047H125.749L125.248 37.2663H120.793L120.514 38.6584H125.749L125.192 41.3314H116.505L118.621 30.8624H127.141L126.584 33.5353H121.517V33.4797Z"
        fill="#0C1822"
      />
      <path
        d="M83.9283 28.802H75.5198L76.0766 26.0177H84.4852L83.9283 28.802Z"
        fill="#E71D36"
      />
      <path
        d="M46.5517 34.7336C47.2945 34.7336 47.8759 35.0244 48.2961 35.6058C48.7162 36.1835 48.9263 36.9769 48.9263 37.986C48.9263 39.0251 48.7087 39.8298 48.2736 40.4C47.8422 40.9702 47.2532 41.2553 46.5067 41.2553C45.7677 41.2553 45.1881 40.987 44.768 40.4506H44.6498L44.3628 41.1427H43.0518V32.3872H44.768V34.4242C44.768 34.683 44.7455 35.0975 44.7005 35.6677H44.768C45.1694 35.045 45.7639 34.7336 46.5517 34.7336ZM46.0003 36.1066C45.5764 36.1066 45.2669 36.2379 45.0718 36.5005C44.8768 36.7593 44.7755 37.1889 44.768 37.7891V37.9748C44.768 38.65 44.8674 39.1339 45.0662 39.4265C45.2688 39.7191 45.5876 39.8654 46.0228 39.8654C46.3754 39.8654 46.6549 39.7041 46.8612 39.3815C47.0713 39.0551 47.1763 38.5825 47.1763 37.9635C47.1763 37.3445 47.0713 36.8813 46.8612 36.5736C46.6511 36.2623 46.3641 36.1066 46.0003 36.1066Z"
        fill="#0C1822"
      />
      <path
        d="M49.444 34.8518H51.3234L52.5106 38.3911C52.6119 38.6988 52.6813 39.0626 52.7188 39.4828H52.7526C52.7939 39.0964 52.8745 38.7325 52.9946 38.3911L54.1593 34.8518H55.9993L53.3378 41.9474C53.094 42.6038 52.7451 43.0953 52.2912 43.4216C51.841 43.748 51.314 43.9112 50.71 43.9112C50.4137 43.9112 50.1229 43.8793 49.8378 43.8155V42.4538C50.0442 42.5026 50.2692 42.5269 50.5131 42.5269C50.8169 42.5269 51.0814 42.4332 51.3065 42.2456C51.5353 42.0618 51.7135 41.7823 51.841 41.4072L51.9423 41.0977L49.444 34.8518Z"
        fill="#0C1822"
      />
    </svg>
  );
};
