type Props = {
  color: string;
};
export const UnZoomSvg = ({ color }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.5C0 11.2239 0.223858 11 0.5 11H23.5C23.7761 11 24 11.2239 24 11.5C24 11.7761 23.7761 12 23.5 12H0.5C0.223857 12 0 11.7761 0 11.5Z"
        fill={color}
      />
    </svg>
  );
};
