import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import ReactMapGL, { MapRef } from "react-map-gl";
import { REACT_APP_MAPBOX_API_TOKEN } from "../../config";
import { DeviceMarker } from "./components/DeviceMarker";
import { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { InteractionButtons } from "./components/InteractionButtons";
import { Logo } from "./components/Logo";
import { DataCell } from "./components/DataCell";

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires */
(mapboxgl as any).workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
/* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires*/

const MAX_ZOOM = 20;
const DEFAULT_ZOOM = 15;
const PARIS_LATITUDE = 48.856614;
const PARIS_LONGITUDE = 2.3522219;

type Props = {
  user?: User;
};

export const Map = ({ user }: Props) => {
  const device = user?.devices?.[0];

  const mapRef = useRef<MapRef>(null);

  const theme = useTheme();

  const mapUrl = theme.map.styleURL;

  const [mapZoom, setMapZoom] = useState(DEFAULT_ZOOM);
  const [isFollowingDevice, setIsFollowingDevice] = useState(true);

  useEffect(() => {
    if (!device?.longitude || !device?.latitude || !isFollowingDevice) return;
    mapRef?.current?.flyTo({
      center: [device.longitude, device.latitude],
    });
  }, [device?.latitude, device?.longitude, isFollowingDevice]);

  useEffect(() => {
    mapRef.current?.zoomTo(mapZoom);
  }, [mapZoom]);

  const zoom = () => {
    if (mapZoom < MAX_ZOOM) {
      setMapZoom(mapZoom + 1);
    }
  };
  const unZoom = () => {
    if (mapZoom > 0 && mapRef.current) {
      setMapZoom(mapZoom - 1);
    }
  };

  const recenterToMarker = () => {
    if (!device?.longitude || !device?.latitude) return;
    const { longitude, latitude } = device;
    setIsFollowingDevice(true);
    mapRef.current?.setCenter([longitude, latitude]);
    mapRef.current?.zoomTo(DEFAULT_ZOOM);
  };

  const isMaxZoom = mapZoom === MAX_ZOOM;
  const isMinZoom = mapZoom === 0;

  return (
    <MapWrapper>
      <LogoAndDeviceDataWrapper>
        <Logo />
        <DataCell user={user} />
      </LogoAndDeviceDataWrapper>
      <InteractionButtons
        zoom={zoom}
        unZoom={unZoom}
        recenterToMarker={recenterToMarker}
        isMaxZoom={isMaxZoom}
        isMinZoom={isMinZoom}
      />
      <ReactMapGL
        ref={mapRef}
        initialViewState={{
          longitude: device?.longitude ?? PARIS_LONGITUDE,
          latitude: device?.latitude ?? PARIS_LATITUDE,
          zoom: DEFAULT_ZOOM,
        }}
        style={{ width: "100vw", height: "100vh" }}
        mapStyle={mapUrl}
        mapboxAccessToken={REACT_APP_MAPBOX_API_TOKEN}
        onDrag={() => {
          setIsFollowingDevice(false);
        }}
      >
        {device && <DeviceMarker device={device} />}
      </ReactMapGL>
    </MapWrapper>
  );
};

const MapWrapper = styled.div``;

const LogoAndDeviceDataWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  z-index: 2;
  top: 32px;
  left: 32px;
  gap: 8px;
  flex-direction: column;
`;
