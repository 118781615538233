export const LocationSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.05494V1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V3.05494C17.1714 3.51613 20.4839 6.8286 20.9451 11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H20.9451C20.4839 17.1714 17.1714 20.4839 13 20.9451V23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23V20.9451C6.8286 20.4839 3.51613 17.1714 3.05494 13H1C0.447715 13 0 12.5523 0 12C0 11.4477 0.447715 11 1 11H3.05494C3.51613 6.8286 6.8286 3.51613 11 3.05494ZM11 5.0709C7.93451 5.50947 5.50947 7.93451 5.0709 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13H5.0709C5.50947 16.0655 7.93451 18.4905 11 18.9291V17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17V18.9291C16.0655 18.4905 18.4905 16.0655 18.9291 13H17C16.4477 13 16 12.5523 16 12C16 11.4477 16.4477 11 17 11H18.9291C18.4905 7.93451 16.0655 5.50947 13 5.0709V7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7V5.0709Z"
        fill="#0C1822"
      />
    </svg>
  );
};
