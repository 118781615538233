import { Map } from "./Map";
import { useQueryUser } from "./useQueryUser";
import styled from "styled-components";
import { LoadingCell } from "../components/LoadingCell";
import { useEffect } from "react";

type Props = {
  logout: () => void;
};

export const MainScreen = (props: Props) => {
  const { logout } = props;
  const { data, error } = useQueryUser();

  useEffect(() => {
    if (error?.message === "notAuthenticated") {
      logout();
    }
  }, [error, logout]);

  const user = data?.user;
  if (!user) return <LoadingCell />;

  return (
    <Container>
      <Map user={user} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background.main};
  padding: 4px;
  flex: 1;
`;
