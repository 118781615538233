import React, { createContext, useContext, useEffect, useState } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "../resources/colors";

export enum Themes {
  Light = "light",
  Dark = "dark",
}

export enum ThemeSelections {
  User = "user",
  Light = "light",
  Dark = "dark",
}

const ThemeContext = createContext({
  themeState: Themes.Light,
  themeSelectedByUser: ThemeSelections.User,
  selectionTheme: (_themeSelected: ThemeSelections) => {},
});

export const useThemeContext = () => useContext(ThemeContext);

type ThemeContextProps = {
  children: React.ReactNode;
};

export const ThemeProviderManager = ({ children }: ThemeContextProps) => {
  const [themeState, setThemeState] = useState<Themes>(Themes.Light);
  const [themeSelectedByUser, setThemeSelectedByUser] =
    useState<ThemeSelections>(ThemeSelections.User);

  const [currentTheme, setCurrentTheme] = useState<DefaultTheme>(lightTheme);

  useEffect(() => {
    themeState === Themes.Dark
      ? setCurrentTheme(darkTheme)
      : setCurrentTheme(lightTheme);
  }, [themeState]);

  const selectionTheme = (themeSelected: ThemeSelections) => {
    setThemeSelectedByUser(themeSelected);
  };

  return (
    <ThemeContext.Provider
      value={{ themeState, themeSelectedByUser, selectionTheme }}
    >
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
