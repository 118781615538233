import styled from "styled-components";
import { CaptionText } from "../base";

export const BoldCaptionText = styled(CaptionText)`
  font-weight: 700;
`;

export const AzulBoldCaptionText = styled(BoldCaptionText)`
  color: #0157e7;
`;
