import styled from "styled-components";
import { FlashbirdLogoSvg } from "../assets/svg";
import { BoldBodyText } from "./text";

export const InvalidSessionCell = () => {
  return (
    <InvalidSessionWrapper>
      <FlashbirdLogoSvg />
      <TextContainer>
        <BoldBodyText>{translations.fr}</BoldBodyText>
        <BoldBodyText>{translations.en}</BoldBodyText>
        <BoldBodyText>{translations.de}</BoldBodyText>
        <BoldBodyText>{translations.nl}</BoldBodyText>
        <BoldBodyText>{translations.it}</BoldBodyText>
        <BoldBodyText>{translations.es}</BoldBodyText>
      </TextContainer>
    </InvalidSessionWrapper>
  );
};

const InvalidSessionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

// the app have several rides saved in history
// the app shows the current position of the vehicle during a ride
// this can be shared with a link
const translations = {
  fr: "🇫🇷 Le partage de trajet est terminé. Pour accéder à la position du véhicule, un nouveau lien doit être généré depuis l'application !",
  en: "🇬🇧 The ride sharing has ended. To access the vehicle's location, a new link must be generated from the app!",
  es: "🇪🇸 El compartir el trayecto ha terminado. Para acceder a la ubicación del vehículo, se debe generar un nuevo enlace desde la aplicación!",
  de: "🇩🇪 Die Fahrtteilung ist beendet. Um auf den Standort des Fahrzeugs zugreifen zu können, muss ein neuer Link aus der App generiert werden!",
  it: "🇮🇹 La condivisione del percorso è terminata. Per accedere alla posizione del veicolo, un nuovo link deve essere generato dall'app!",
  nl: "🇳🇱 Het delen van de rit is beëindigd. Om de locatie van het voertuig te kunnen bereiken, moet er een nieuwe link vanuit de app gegenereerd worden!",
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  gap: 16px;
`;
