import "./App.css";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client";
import { MainScreen } from "./MainScreen";
import styled from "styled-components";
import {
  clearAuthenticationToken,
  saveAuthenticationToken,
} from "./SessionStorage";
import { GlobalStyles } from "./components/theme/GlobalStyle";
import { ThemeProviderManager } from "./context/useThemeMode";
import { InvalidSessionCell } from "./components/InvalidSessionCell";

const INVALID_SESSION_SCREEN_KEY = "INVALID_SESSION_SCREEN_KEY";
const MAIN_SCREEN_KEY = "MAIN_SCREEN_KEY";

function App() {
  const token = getTokenFromLocation();
  saveAuthenticationToken(token);
  const currentScreenKey = token ? MAIN_SCREEN_KEY : INVALID_SESSION_SCREEN_KEY;

  const logout = () => {
    clearAuthenticationToken();
    window.location.href = "/";
  };

  return (
    <ApolloProvider client={client}>
      <ThemeProviderManager>
        <GlobalStyles />
        <Container>
          {currentScreenKey === INVALID_SESSION_SCREEN_KEY && (
            <InvalidSessionCell />
          )}
          {currentScreenKey === MAIN_SCREEN_KEY && (
            <MainScreen logout={logout} />
          )}
        </Container>
      </ThemeProviderManager>
    </ApolloProvider>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.background.main};
  align-items: center;
  justify-content: center;
`;

export default App;

const getTokenFromLocation = () => {
  const pathname = window.location.pathname;
  const token = pathname.split("/")?.[1];
  return token;
};
