type Props = {
  color: string;
};

export const CrossSvg = ({ color }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C12.2761 24 12.5 23.7761 12.5 23.5V12.5H23.5C23.7762 12.5 24 12.2761 24 12C24 11.7239 23.7762 11.5 23.5 11.5H12.5V0.5C12.5 0.2239 12.2761 0 12 0C11.7239 0 11.5 0.2239 11.5 0.5V11.5H0.5C0.2239 11.5 0 11.7239 0 12C0 12.2761 0.2239 12.5 0.5 12.5H11.5V23.5C11.5 23.7761 11.7239 24 12 24Z"
        fill={color}
      />
    </svg>
  );
};
