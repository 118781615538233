import styled from "styled-components";
import { FlashbirdLogoSvg } from "../../../assets/svg";

export const Logo = () => {
  return (
    <LogoWrapper>
      <FlashbirdLogoSvg />
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  padding: 8px;
  background: #fcfcfc;
  box-shadow: 0px 2px 5px rgba(12, 24, 34, 0.08),
    0px 0px 2px 1px rgba(12, 24, 34, 0.05);
  border-radius: 4px;
  width: 191px;
  display: flex;
  justify-content: flex-start;
`;
