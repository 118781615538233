import styled from "styled-components";
import { CrossSvg, UnZoomSvg } from "../../../assets/svg";
import { LocationSvg } from "../../../assets/svg/buttons/LocationSvg";
import { RoundedButton } from "../../../components/buttons/roundedButtons";

type Props = {
  zoom: () => void;
  unZoom: () => void;
  recenterToMarker: () => void;
  isMaxZoom: boolean;
  isMinZoom: boolean;
};

export const InteractionButtons = ({
  zoom,
  unZoom,
  isMaxZoom,
  isMinZoom,
  recenterToMarker,
}: Props) => {
  const CrossSvgColor = isMaxZoom ? "#0C1822" : " #0157E7";
  const unZoomSvgColor = isMinZoom ? "#0C1822" : " #0157E7";

  return (
    <InteractionButtonWrapper>
      <RoundedButton
        onClick={zoom}
        svg={<CrossSvg color={CrossSvgColor} />}
        isDisabled={isMaxZoom}
      />
      <RoundedButton
        onClick={unZoom}
        svg={<UnZoomSvg color={unZoomSvgColor} />}
        isDisabled={isMinZoom}
      />
      <RoundedButton onClick={recenterToMarker} svg={<LocationSvg />} />
    </InteractionButtonWrapper>
  );
};
const InteractionButtonWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
