import React from "react";
import styled from "styled-components";

type Props = {
  onClick?: () => void;
  isDisabled?: boolean;
  svg: React.ReactNode;
};

export const RoundedButton = ({ onClick, isDisabled, svg }: Props) => {
  return (
    <StyledRoundedButton onClick={onClick} disabled={isDisabled ?? false}>
      {svg}
    </StyledRoundedButton>
  );
};

const StyledRoundedButton = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(12, 24, 34, 0.08),
    0px 0px 2px 1px rgba(12, 24, 34, 0.05);
  border: none;
  border-radius: 80px;
`;
