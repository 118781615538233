import styled from "styled-components";
import { FlashbirdLogoSvg } from "../assets/svg";
import { BoldBodyText } from "./text";

export const LoadingCell = () => {
  return (
    <LoadingWrapper>
      <FlashbirdLogoSvg />
      <BoldBodyText>Loading</BoldBodyText>
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
