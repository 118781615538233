const AUTHENTICATION_TOKEN_KEY = "AUTHENTICATION_TOKEN_KEY";

export const saveAuthenticationToken = (authenticationToken: string) => {
  sessionStorage.setItem(AUTHENTICATION_TOKEN_KEY, authenticationToken);
};

export const getAuthenticationToken = () => {
  const authenticationToken = sessionStorage.getItem(AUTHENTICATION_TOKEN_KEY);
  return authenticationToken;
};

export const clearAuthenticationToken = () => {
  sessionStorage.removeItem(AUTHENTICATION_TOKEN_KEY);
};
