import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const QUERY_USER = gql`
  query QueryDevicesLocation {
    user {
      id
      username
      devices {
        id
        name
        vehicleType
        longitude
        latitude
      }
    }
  }
`;

type QueryUserResult = {
  user: User;
};

export const useQueryUser = () =>
  useQuery<QueryUserResult>(QUERY_USER, {
    pollInterval: 1000,
  });
