export const CarMarkerSvg = () => {
  return (
    <svg
      width="51"
      height="56"
      viewBox="0 0 51 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_10849_215954)">
        <path
          d="M47 25.5C47 37.3741 37.3741 47 25.5 47C13.6259 47 4 37.3741 4 25.5C4 13.6259 13.6259 4 25.5 4C37.3741 4 47 13.6259 47 25.5Z"
          fill="#0157E7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.6178 45.8919C37.9446 44.8321 46 36.1068 46 25.5C46 14.1782 36.8218 5 25.5 5C14.1782 5 5 14.1782 5 25.5C5 35.7415 12.5102 44.229 22.3238 45.7554L25.0001 52L27.6178 45.8919Z"
          fill="#FCFCFC"
        />
        <path
          d="M37.5 30.5H38.5C42 30.5 41.5 29 41.5 27.5C41.5 26.3 40.8333 25.6667 40.5 25.5L31.5 24.5C31.5 24.5 28 21.5 26.5 21.5C18.5 21.5 17.5 22.5 15 24.5H14L13.5 23.5C13.5 23.5 11.3333 23.5 10.5 23.5L12.5 25.5C11.5 25.5 10.5 26 10.5 27C10.5 27.4 10.5 28 10.5 28.5C10.5 30 11.1 30.5 13.5 30.5H14.5"
          stroke="#0C1822"
          stroke-linejoin="round"
        />
        <circle cx="16.5" cy="30.5" r="2" stroke="#0C1822" />
        <circle cx="35.5" cy="30.5" r="2" stroke="#0C1822" />
        <path d="M33.5 30.5H18.5" stroke="#0C1822" />
      </g>
      <defs>
        <filter
          id="filter0_d_10849_215954"
          x="-3"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0470588 0 0 0 0 0.0941176 0 0 0 0 0.133333 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10849_215954"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10849_215954"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
